.card label {
    font-weight: bold;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    color: #212529;
}
.bg-red {
    background-color: #f70709 !important;
}
.title-header {
    font-size: 1.5rem;
    font-weight: bold;
}
.title-header1 {
    font-size: 1.2rem;
}
.title-report {
    font-size: 1.3rem;
    font-weight: bold;
}
.title-maintenance-item {
    font-size: 1.2rem;
    font-weight: bold;
}
.title-service-records {
    position: relative;
    top: 10px;
    font-size: 1.2rem;
    font-weight: bold;
}
.btn-maintenance {
    margin-top: -5px;
}
.input-mini {
  width: 60px;
}
.input-small {
  width: 90px;
}
.input-medium {
  width: 150px;
}
.input-large {
  width: 210px;
}
.input-xlarge {
  width: 270px;
}
.input-xxlarge {
  width: 530px;
}
