.alert{
  .close{
    font-weight: 300;
    font-size: 29px;
    i.fa,
    i.fas,
    i.far,
    i.fal,
    i.fab,
    i.nc-icon{
      font-size: 14px !important;
    }
  }
}

;@import "sass-embedded-legacy-load-done:34";