.ReactTable
{
		position:relative;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;background-color:initial
}
.ReactTable.primary-pagination .-pagination .-btn
{
	background-color:#fb9e20;color:#fff
}
.ReactTable.primary-pagination .-pagination .-btn.active,.ReactTable.primary-pagination .-pagination .-btn.active:focus,.ReactTable.primary-pagination .-pagination .-btn.active:hover,.ReactTable.primary-pagination .-pagination .-btn:active,.ReactTable.primary-pagination .-pagination .-btn:active:focus,.ReactTable.primary-pagination .-pagination .-btn:active:hover,.ReactTable.primary-pagination .-pagination .-btn:focus,.ReactTable.primary-pagination .-pagination .-btn:hover,.show>.ReactTable.primary-pagination .-pagination .-btn.dropdown-toggle,.show>.ReactTable.primary-pagination .-pagination .-btn.dropdown-toggle:focus,.show>.ReactTable.primary-pagination .-pagination .-btn.dropdown-toggle:hover
{
	background-color:#e48504!important;color:#fff!important;box-shadow:none!important
}
.ReactTable.primary-pagination .-pagination .-btn:not([data-action]):hover
{
	box-shadow:none
}
.ReactTable.primary-pagination .-pagination .-btn.disabled,.ReactTable.primary-pagination .-pagination .-btn.disabled.active,.ReactTable.primary-pagination .-pagination .-btn.disabled.focus,.ReactTable.primary-pagination .-pagination .-btn.disabled:active,.ReactTable.primary-pagination .-pagination .-btn.disabled:focus,.ReactTable.primary-pagination .-pagination .-btn.disabled:hover,.ReactTable.primary-pagination .-pagination .-btn:disabled,.ReactTable.primary-pagination .-pagination .-btn:disabled.active,.ReactTable.primary-pagination .-pagination .-btn:disabled.focus,.ReactTable.primary-pagination .-pagination .-btn:disabled:active,.ReactTable.primary-pagination .-pagination .-btn:disabled:focus,.ReactTable.primary-pagination .-pagination .-btn:disabled:hover,.ReactTable.primary-pagination .-pagination .-btn[disabled],.ReactTable.primary-pagination .-pagination .-btn[disabled].active,.ReactTable.primary-pagination .-pagination .-btn[disabled].focus,.ReactTable.primary-pagination .-pagination .-btn[disabled]:active,.ReactTable.primary-pagination .-pagination .-btn[disabled]:focus,.ReactTable.primary-pagination .-pagination .-btn[disabled]:hover,fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn,fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn.active,fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn.focus,fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn:active,fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn:focus,fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn:hover
{
	background-color:#fb9e20;border-color:#fb9e20
}
.ReactTable.primary-pagination .-pagination .-btn.btn-simple
{
	color:#fb9e20;border-color:#fb9e20
}
.ReactTable.primary-pagination .-pagination .-btn.btn-simple:active,.ReactTable.primary-pagination .-pagination .-btn.btn-simple:focus,.ReactTable.primary-pagination .-pagination .-btn.btn-simple:hover
{
	background-color:transparent;color:#e48504;border-color:#e48504;box-shadow:none
}
.ReactTable.primary-pagination .-pagination .-btn.btn-link
{
	color:#fb9e20
}
.ReactTable.primary-pagination .-pagination .-btn.btn-link:active,.ReactTable.primary-pagination .-pagination .-btn.btn-link:active:focus,.ReactTable.primary-pagination .-pagination .-btn.btn-link:focus,.ReactTable.primary-pagination .-pagination .-btn.btn-link:hover
{
	background-color:transparent;color:#e48504;text-decoration:none;box-shadow:none
}
.ReactTable.primary-pagination .-pagination .-btn:disabled
{
	opacity:.5;filter:alpha(opacity=50);pointer-events:none;cursor:not-allowed
}
.ReactTable.info-pagination .-pagination .-btn
{
	background-color:#51bcda;color:#fff
}
.ReactTable.info-pagination .-pagination .-btn.active,.ReactTable.info-pagination .-pagination .-btn.active:focus,.ReactTable.info-pagination .-pagination .-btn.active:hover,.ReactTable.info-pagination .-pagination .-btn:active,.ReactTable.info-pagination .-pagination .-btn:active:focus,.ReactTable.info-pagination .-pagination .-btn:active:hover,.ReactTable.info-pagination .-pagination .-btn:focus,.ReactTable.info-pagination .-pagination .-btn:hover,.show>.ReactTable.info-pagination .-pagination .-btn.dropdown-toggle,.show>.ReactTable.info-pagination .-pagination .-btn.dropdown-toggle:focus,.show>.ReactTable.info-pagination .-pagination .-btn.dropdown-toggle:hover
{
	background-color:#2ba9cd!important;color:#fff!important;box-shadow:none!important
}
.ReactTable.info-pagination .-pagination .-btn:not([data-action]):hover
{
	box-shadow:none
}
.ReactTable.info-pagination .-pagination .-btn.disabled,.ReactTable.info-pagination .-pagination .-btn.disabled.active,.ReactTable.info-pagination .-pagination .-btn.disabled.focus,.ReactTable.info-pagination .-pagination .-btn.disabled:active,.ReactTable.info-pagination .-pagination .-btn.disabled:focus,.ReactTable.info-pagination .-pagination .-btn.disabled:hover,.ReactTable.info-pagination .-pagination .-btn:disabled,.ReactTable.info-pagination .-pagination .-btn:disabled.active,.ReactTable.info-pagination .-pagination .-btn:disabled.focus,.ReactTable.info-pagination .-pagination .-btn:disabled:active,.ReactTable.info-pagination .-pagination .-btn:disabled:focus,.ReactTable.info-pagination .-pagination .-btn:disabled:hover,.ReactTable.info-pagination .-pagination .-btn[disabled],.ReactTable.info-pagination .-pagination .-btn[disabled].active,.ReactTable.info-pagination .-pagination .-btn[disabled].focus,.ReactTable.info-pagination .-pagination .-btn[disabled]:active,.ReactTable.info-pagination .-pagination .-btn[disabled]:focus,.ReactTable.info-pagination .-pagination .-btn[disabled]:hover,fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn,fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn.active,fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn.focus,fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn:active,fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn:focus,fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn:hover
{
	background-color:#51bcda;border-color:#51bcda
}
.ReactTable.info-pagination .-pagination .-btn.btn-simple
{
	color:#51bcda;border-color:#51bcda
}
.ReactTable.info-pagination .-pagination .-btn.btn-simple:active,.ReactTable.info-pagination .-pagination .-btn.btn-simple:focus,.ReactTable.info-pagination .-pagination .-btn.btn-simple:hover
{
	background-color:transparent;color:#2ba9cd;border-color:#2ba9cd;box-shadow:none
}
.ReactTable.info-pagination .-pagination .-btn.btn-link
{
	color:#51bcda
}
.ReactTable.info-pagination .-pagination .-btn.btn-link:active,.ReactTable.info-pagination .-pagination .-btn.btn-link:active:focus,.ReactTable.info-pagination .-pagination .-btn.btn-link:focus,.ReactTable.info-pagination .-pagination .-btn.btn-link:hover
{
	background-color:transparent;color:#2ba9cd;text-decoration:none;box-shadow:none
}
.ReactTable.info-pagination .-pagination .-btn:disabled
{
	opacity:.5;filter:alpha(opacity=50);pointer-events:none;cursor:not-allowed
}
.ReactTable.success-pagination .-pagination .-btn
{
	background-color:#6bd098;color:#fff
}
.ReactTable.success-pagination .-pagination .-btn.active,.ReactTable.success-pagination .-pagination .-btn.active:focus,.ReactTable.success-pagination .-pagination .-btn.active:hover,.ReactTable.success-pagination .-pagination .-btn:active,.ReactTable.success-pagination .-pagination .-btn:active:focus,.ReactTable.success-pagination .-pagination .-btn:active:hover,.ReactTable.success-pagination .-pagination .-btn:focus,.ReactTable.success-pagination .-pagination .-btn:hover,.show>.ReactTable.success-pagination .-pagination .-btn.dropdown-toggle,.show>.ReactTable.success-pagination .-pagination .-btn.dropdown-toggle:focus,.show>.ReactTable.success-pagination .-pagination .-btn.dropdown-toggle:hover
{
	background-color:#44c47d!important;color:#fff!important;box-shadow:none!important
}
.ReactTable.success-pagination .-pagination .-btn:not([data-action]):hover
{
	box-shadow:none
}
.ReactTable.success-pagination .-pagination .-btn.disabled,.ReactTable.success-pagination .-pagination .-btn.disabled.active,.ReactTable.success-pagination .-pagination .-btn.disabled.focus,.ReactTable.success-pagination .-pagination .-btn.disabled:active,.ReactTable.success-pagination .-pagination .-btn.disabled:focus,.ReactTable.success-pagination .-pagination .-btn.disabled:hover,.ReactTable.success-pagination .-pagination .-btn:disabled,.ReactTable.success-pagination .-pagination .-btn:disabled.active,.ReactTable.success-pagination .-pagination .-btn:disabled.focus,.ReactTable.success-pagination .-pagination .-btn:disabled:active,.ReactTable.success-pagination .-pagination .-btn:disabled:focus,.ReactTable.success-pagination .-pagination .-btn:disabled:hover,.ReactTable.success-pagination .-pagination .-btn[disabled],.ReactTable.success-pagination .-pagination .-btn[disabled].active,.ReactTable.success-pagination .-pagination .-btn[disabled].focus,.ReactTable.success-pagination .-pagination .-btn[disabled]:active,.ReactTable.success-pagination .-pagination .-btn[disabled]:focus,.ReactTable.success-pagination .-pagination .-btn[disabled]:hover,fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn,fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn.active,fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn.focus,fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn:active,fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn:focus,fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn:hover
{
	background-color:#6bd098;border-color:#6bd098
}
.ReactTable.success-pagination .-pagination .-btn.btn-simple
{
	color:#6bd098;border-color:#6bd098
}
.ReactTable.success-pagination .-pagination .-btn.btn-simple:active,.ReactTable.success-pagination .-pagination .-btn.btn-simple:focus,.ReactTable.success-pagination .-pagination .-btn.btn-simple:hover
{
	background-color:transparent;color:#44c47d;border-color:#44c47d;box-shadow:none
}
.ReactTable.success-pagination .-pagination .-btn.btn-link
{
	color:#6bd098
}
.ReactTable.success-pagination .-pagination .-btn.btn-link:active,.ReactTable.success-pagination .-pagination .-btn.btn-link:active:focus,.ReactTable.success-pagination .-pagination .-btn.btn-link:focus,.ReactTable.success-pagination .-pagination .-btn.btn-link:hover
{
	background-color:transparent;color:#44c47d;text-decoration:none;box-shadow:none
}
.ReactTable.success-pagination .-pagination .-btn:disabled
{
	opacity:.5;filter:alpha(opacity=50);pointer-events:none;cursor:not-allowed
}
.ReactTable.warning-pagination .-pagination .-btn
{
	background-color:#fbc658;color:#fff
}
.ReactTable.warning-pagination .-pagination .-btn.active,.ReactTable.warning-pagination .-pagination .-btn.active:focus,.ReactTable.warning-pagination .-pagination .-btn.active:hover,.ReactTable.warning-pagination .-pagination .-btn:active,.ReactTable.warning-pagination .-pagination .-btn:active:focus,.ReactTable.warning-pagination .-pagination .-btn:active:hover,.ReactTable.warning-pagination .-pagination .-btn:focus,.ReactTable.warning-pagination .-pagination .-btn:hover,.show>.ReactTable.warning-pagination .-pagination .-btn.dropdown-toggle,.show>.ReactTable.warning-pagination .-pagination .-btn.dropdown-toggle:focus,.show>.ReactTable.warning-pagination .-pagination .-btn.dropdown-toggle:hover
{
	background-color:#fab526!important;color:#fff!important;box-shadow:none!important
}
.ReactTable.warning-pagination .-pagination .-btn:not([data-action]):hover
{
	box-shadow:none
}
.ReactTable.warning-pagination .-pagination .-btn.disabled,.ReactTable.warning-pagination .-pagination .-btn.disabled.active,.ReactTable.warning-pagination .-pagination .-btn.disabled.focus,.ReactTable.warning-pagination .-pagination .-btn.disabled:active,.ReactTable.warning-pagination .-pagination .-btn.disabled:focus,.ReactTable.warning-pagination .-pagination .-btn.disabled:hover,.ReactTable.warning-pagination .-pagination .-btn:disabled,.ReactTable.warning-pagination .-pagination .-btn:disabled.active,.ReactTable.warning-pagination .-pagination .-btn:disabled.focus,.ReactTable.warning-pagination .-pagination .-btn:disabled:active,.ReactTable.warning-pagination .-pagination .-btn:disabled:focus,.ReactTable.warning-pagination .-pagination .-btn:disabled:hover,.ReactTable.warning-pagination .-pagination .-btn[disabled],.ReactTable.warning-pagination .-pagination .-btn[disabled].active,.ReactTable.warning-pagination .-pagination .-btn[disabled].focus,.ReactTable.warning-pagination .-pagination .-btn[disabled]:active,.ReactTable.warning-pagination .-pagination .-btn[disabled]:focus,.ReactTable.warning-pagination .-pagination .-btn[disabled]:hover,fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn,fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn.active,fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn.focus,fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn:active,fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn:focus,fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn:hover
{
	background-color:#fbc658;border-color:#fbc658
}
.ReactTable.warning-pagination .-pagination .-btn.btn-simple
{
	color:#fbc658;border-color:#fbc658
}
.ReactTable.warning-pagination .-pagination .-btn.btn-simple:active,.ReactTable.warning-pagination .-pagination .-btn.btn-simple:focus,.ReactTable.warning-pagination .-pagination .-btn.btn-simple:hover
{
	background-color:transparent;color:#fab526;border-color:#fab526;box-shadow:none
}
.ReactTable.warning-pagination .-pagination .-btn.btn-link
{
	color:#fbc658
}
.ReactTable.warning-pagination .-pagination .-btn.btn-link:active,.ReactTable.warning-pagination .-pagination .-btn.btn-link:active:focus,.ReactTable.warning-pagination .-pagination .-btn.btn-link:focus,.ReactTable.warning-pagination .-pagination .-btn.btn-link:hover
{
	background-color:transparent;color:#fab526;text-decoration:none;box-shadow:none
}
.ReactTable.warning-pagination .-pagination .-btn:disabled
{
	opacity:.5;filter:alpha(opacity=50);pointer-events:none;cursor:not-allowed
}
.ReactTable.danger-pagination .-pagination .-btn
{
	background-color:#ef8157;color:#fff
}
.ReactTable.danger-pagination .-pagination .-btn.active,.ReactTable.danger-pagination .-pagination .-btn.active:focus,.ReactTable.danger-pagination .-pagination .-btn.active:hover,.ReactTable.danger-pagination .-pagination .-btn:active,.ReactTable.danger-pagination .-pagination .-btn:active:focus,.ReactTable.danger-pagination .-pagination .-btn:active:hover,.ReactTable.danger-pagination .-pagination .-btn:focus,.ReactTable.danger-pagination .-pagination .-btn:hover,.show>.ReactTable.danger-pagination .-pagination .-btn.dropdown-toggle,.show>.ReactTable.danger-pagination .-pagination .-btn.dropdown-toggle:focus,.show>.ReactTable.danger-pagination .-pagination .-btn.dropdown-toggle:hover
{
	background-color:#eb6532!important;color:#fff!important;box-shadow:none!important
}
.ReactTable.danger-pagination .-pagination .-btn:not([data-action]):hover
{
	box-shadow:none
}
.ReactTable.danger-pagination .-pagination .-btn.disabled,.ReactTable.danger-pagination .-pagination .-btn.disabled.active,.ReactTable.danger-pagination .-pagination .-btn.disabled.focus,.ReactTable.danger-pagination .-pagination .-btn.disabled:active,.ReactTable.danger-pagination .-pagination .-btn.disabled:focus,.ReactTable.danger-pagination .-pagination .-btn.disabled:hover,.ReactTable.danger-pagination .-pagination .-btn:disabled,.ReactTable.danger-pagination .-pagination .-btn:disabled.active,.ReactTable.danger-pagination .-pagination .-btn:disabled.focus,.ReactTable.danger-pagination .-pagination .-btn:disabled:active,.ReactTable.danger-pagination .-pagination .-btn:disabled:focus,.ReactTable.danger-pagination .-pagination .-btn:disabled:hover,.ReactTable.danger-pagination .-pagination .-btn[disabled],.ReactTable.danger-pagination .-pagination .-btn[disabled].active,.ReactTable.danger-pagination .-pagination .-btn[disabled].focus,.ReactTable.danger-pagination .-pagination .-btn[disabled]:active,.ReactTable.danger-pagination .-pagination .-btn[disabled]:focus,.ReactTable.danger-pagination .-pagination .-btn[disabled]:hover,fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn,fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn.active,fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn.focus,fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn:active,fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn:focus,fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn:hover
{
	background-color:#ef8157;border-color:#ef8157
}
.ReactTable.danger-pagination .-pagination .-btn.btn-simple
{
	color:#ef8157;border-color:#ef8157
}
.ReactTable.danger-pagination .-pagination .-btn.btn-simple:active,.ReactTable.danger-pagination .-pagination .-btn.btn-simple:focus,.ReactTable.danger-pagination .-pagination .-btn.btn-simple:hover
{
	background-color:transparent;color:#eb6532;border-color:#eb6532;box-shadow:none
}
.ReactTable.danger-pagination .-pagination .-btn.btn-link
{
	color:#ef8157
}
.ReactTable.danger-pagination .-pagination .-btn.btn-link:active,.ReactTable.danger-pagination .-pagination .-btn.btn-link:active:focus,.ReactTable.danger-pagination .-pagination .-btn.btn-link:focus,.ReactTable.danger-pagination .-pagination .-btn.btn-link:hover
{
	background-color:transparent;color:#eb6532;text-decoration:none;box-shadow:none
}
.ReactTable.danger-pagination .-pagination .-btn:disabled
{
	opacity:.5;filter:alpha(opacity=50);pointer-events:none;cursor:not-allowed
}
.ReactTable .actions-right
{
	text-align:right
}
.ReactTable .actions-left
{
	text-align:left
}
.ReactTable .actions-center
{
	text-align:center
}
.ReactTable *
{
	box-sizing:border-box
}
.ReactTable .rt-table
{
	-webkit-flex:auto 1;flex:auto 1;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:stretch;align-items:stretch;width:100%;border-collapse:collapse;overflow:auto
}
.ReactTable .rt-thead
{
	-webkit-flex:1 0 auto;flex:1 0 auto;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-user-select:none;user-select:none
}
.ReactTable .rt-thead.-headerGroups
{
	background:rgba(0,0,0,.03);border-bottom:1px solid rgba(0,0,0,.05)
}
.ReactTable .rt-thead.-filters input,.ReactTable .rt-thead.-filters select
{
	border:1px solid #e3e3e3;border-radius:4px;color:#565656;font-weight:400;height:28px;background-color:inherit;padding-left:.8em
}
.ReactTable .rt-thead.-filters input:focus,.ReactTable .rt-thead.-filters select:focus
{
	outline:none;border:1px solid #aaa;color:#333
}
.ReactTable .rt-thead .rt-tr
{
	text-align:center
}
.ReactTable .rt-thead .rt-th.-cursor-pointer>div:first-of-type:after
{
	opacity:.6;content:"\F0DC";position:relative;display:inline-block;bottom:0;right:-7px;font-family:"FontAwesome", sans-serif
}
.ReactTable .rt-thead .rt-th.-cursor-pointer.-sort-desc>div:first-of-type:after
{
	content:"\F0DD";top:-3px;position:relative;display:inline-block;bottom:0;right:-7px;font-family:"FontAwesome", sans-serif;opacity:1
}
.ReactTable .rt-thead .rt-th.-cursor-pointer.-sort-asc>div:first-of-type:after
{
	content:"\F0DE";top:3px;position:relative;display:inline-block;bottom:0;right:-7px;font-family:"FontAwesome", sans-serif;opacity:1
}
.ReactTable .rt-thead .rt-td,.ReactTable .rt-thead .rt-th
{
	font-size:1.063rem;text-align:left;font-weight:300;line-height:2.5em!important;padding:5px;position:relative;transition:box-shadow .3s cubic-bezier(.175,.885,.32,1.275);box-shadow:inset 0 0 0 0 transparent
}
.ReactTable .rt-thead .rt-td.-cursor-pointer,.ReactTable .rt-thead .rt-th.-cursor-pointer
{
	cursor:pointer
}
.ReactTable .rt-thead .rt-td:last-child,.ReactTable .rt-thead .rt-th:last-child
{
	border-right:0
}
.ReactTable .rt-thead .rt-resizable-header:last-child
{
	overflow:hidden
}
.ReactTable .rt-thead .rt-resizable-header-content
{
	overflow:hidden;text-overflow:ellipsis
}
.ReactTable .rt-thead .rt-resizable-header-content:focus
{
	outline:none
}
.ReactTable .rt-thead .rt-header-pivot
{
	border-right-color:#f7f7f7
}
.ReactTable .rt-thead .rt-header-pivot:after,.ReactTable .rt-thead .rt-header-pivot:before
{
	left:100%;top:50%;border:solid transparent;content:" ";height:0;width:0;position:absolute;pointer-events:none
}
.ReactTable .rt-thead .rt-header-pivot:after
{
	border-color:hsla(0,0%,100%,0) hsla(0,0%,100%,0) hsla(0,0%,100%,0) #fff;border-width:8px;margin-top:-8px
}
.ReactTable .rt-thead .rt-header-pivot:before
{
	border-color:hsla(0,0%,40%,0) hsla(0,0%,40%,0) hsla(0,0%,40%,0) #f7f7f7;border-width:10px;margin-top:-10px
}
.ReactTable .rt-tbody
{
	-webkit-flex:99999 1 auto;flex:99999 1 auto;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;overflow:auto
}
.ReactTable .rt-tbody .rt-tr-group:last-child
{
	border-bottom:0
}
.ReactTable .rt-tbody .rt-td:last-child
{
	border-right:0
}
.ReactTable .rt-tbody .rt-expandable
{
	cursor:pointer
}
.ReactTable .rt-tr-group
{
	-webkit-flex:1 0 auto;flex:1 0 auto;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:stretch;align-items:stretch
}
.ReactTable .rt-tr
{
	-webkit-flex:1 0 auto;flex:1 0 auto;display:-webkit-inline-flex;display:inline-flex
}
.ReactTable .rt-th
{
	-webkit-flex:1 0;flex:1 0;white-space:nowrap;text-overflow:ellipsis;padding:12px 7px;overflow:hidden;transition:.3s ease;transition-property:width,min-width,padding,opacity;line-height:2
}
.ReactTable .rt-td
{
	-webkit-flex:1 0;flex:1 0;text-overflow:ellipsis;padding:12px 7px;overflow:hidden;transition:.3s ease;transition-property:width,min-width,padding,opacity;line-height:2
}
.ReactTable .rt-td.-hidden,.ReactTable .rt-th.-hidden
{
	width:0!important;min-width:0!important;padding:0!important;border:0!important;opacity:0!important
}
.ReactTable .rt-expander
{
	display:inline-block;position:relative;color:transparent;margin:0 10px
}
.ReactTable .rt-expander:after
{
	content:"";position:absolute;width:0;height:0;top:50%;left:50%;-webkit-transform:translate(-50%,-50%) rotate(-90deg);transform:translate(-50%,-50%) rotate(-90deg);border-left:5.04px solid transparent;border-right:5.04px solid transparent;border-top:7px solid rgba(0,0,0,.8);transition:all .3s cubic-bezier(.175,.885,.32,1.275);cursor:pointer
}
.ReactTable .rt-expander.-open:after
{
	-webkit-transform:translate(-50%,-50%) rotate(0);transform:translate(-50%,-50%) rotate(0)
}
.ReactTable .rt-resizer
{
	display:inline-block;position:absolute;width:36px;top:0;bottom:0;right:-18px;cursor:col-resize;z-index:10
}
.ReactTable .rt-tfoot
{
	-webkit-flex:1 0 auto;flex:1 0 auto;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;box-shadow:0 0 15px 0 rgba(0,0,0,.15)
}
.ReactTable .rt-tfoot .rt-td
{
	border-right:1px solid rgba(0,0,0,.05)
}
.ReactTable .rt-tfoot .rt-td:last-child
{
	border-right:0
}
.ReactTable.-striped .rt-tr.-odd
{
	background:rgba(0,0,0,.03)
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover
{
	background:rgba(0,0,0,.05)
}
.ReactTable .-pagination
{
	z-index:1;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:stretch;align-items:stretch;-webkit-flex-wrap:wrap;flex-wrap:wrap;padding:3px
}
.ReactTable .-pagination input,.ReactTable .-pagination select
{
	border:1px solid #e3e3e3;border-radius:4px;color:#565656;font-weight:400;height:28px;background-color:inherit;text-align:center
}
.ReactTable .-pagination input:focus,.ReactTable .-pagination select:focus
{
	outline:none;border:1px solid #aaa;color:#333
}
.ReactTable .-pagination .-btn
{
	-webkit-appearance:none;appearance:none;display:block;width:100%;height:100%;border:0;border-radius:3px;padding:6px;font-size:1em;color:rgba(0,0,0,.6);background:rgba(0,0,0,.1);transition:all .1s ease;cursor:pointer;outline:none
}
.ReactTable .-pagination .-btn[disabled]
{
	opacity:.5;cursor:not-allowed
}
.ReactTable .-pagination .-btn:not([disabled]):hover
{
	background:rgba(0,0,0,.3);color:#fff
}
.ReactTable .-pagination .-next,.ReactTable .-pagination .-previous
{
	-webkit-flex:1 1;flex:1 1;text-align:center
}
.ReactTable .-pagination .-center
{
	-webkit-flex:1.5 1;flex:1.5 1;text-align:center;margin-bottom:0;display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-flex-wrap:wrap;flex-wrap:wrap;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-around;justify-content:space-around
}
.ReactTable .-pagination .-pageInfo
{
	display:inline-block;margin:3px 10px;white-space:nowrap
}
.ReactTable .-pagination .-pageJump
{
	display:inline-block
}
.ReactTable .-pagination .-pageSizeOptions
{
	margin:3px 10px
}
.ReactTable .rt-noData
{
	left:50%;top:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);z-index:1;padding:20px;color:rgba(0,0,0,.5)
}
.ReactTable .-loading,.ReactTable .rt-noData
{
	display:block;position:absolute;background:hsla(0,0%,100%,.8);transition:all .3s ease;pointer-events:none
}
.ReactTable .-loading
{
	left:0;right:0;top:0;bottom:0;z-index:-1;opacity:0
}
.ReactTable .-loading>div
{
	position:absolute;display:block;text-align:center;width:100%;top:50%;left:0;font-size:15px;color:rgba(0,0,0,.6);-webkit-transform:translateY(-52%);transform:translateY(-52%);transition:all .3s cubic-bezier(.25,.46,.45,.94)
}
.ReactTable .-loading.-active
{
	opacity:1;z-index:2;pointer-events:all
}
.ReactTable .-loading.-active>div
{
	-webkit-transform:translateY(50%);transform:translateY(50%)
}
.ReactTable .rt-resizing .rt-td,.ReactTable .rt-resizing .rt-th
{
	transition:none!important;cursor:col-resize;-webkit-user-select:none;user-select:none
}
.ReactTable .rt-thead .rt-th:focus
{
	outline:none
}
.ReactTable .rt-thead .rt-th
{
	font-size:14px;font-weight:700;text-transform:uppercase;border:0
}
.ReactTable .rt-table
{
	border:1px solid #dee2e6
}
.ReactTable .rt-table .rt-td
{
	border-top:1px solid #dee2e6;border-right:1px solid #dee2e6
}
.ReactTable .btn.btn-icon
{
	margin:0 3px;padding:5px 8px
}
