.ac-pagination {
    float: right;
    margin: 28px 0 0 0;
}
.ac-page-item {
    &.active .page-link {
        color: $white-color;
        background-color: $primary-color;
        border-color: $primary-color;
    }
}
.ac-page-link {
    .page-link {
        color: $primary-color;
    }
}
.btn-link {
    cursor: pointer;
}
.col-header {
    margin-bottom: 10px;
    .btn {
        margin-bottom: 0;
    }
}
.custom-checkbox {
    &.error {
        label {
            color: $danger-color;
        }
    }
}
.custom-switch {
    label {
        margin-bottom: 0;
        padding-top: 3px;
        cursor: pointer;
    }
}
.dashboard {
    .card {
        margin-bottom: 20px;
        .card-title {
            margin-bottom: 0;
            font-size: 1em;
        }
    }
}
.form-section {
    .form-label {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
.input.error {
    border-color: red;
}
label.custom-control-label {
    padding-top: 2px;
    font-size: 1em;
    cursor: pointer;
}
.modal-header {
    background-color: $title-color;
}
.modal-header .close {
    color: $white-color;
    &:hover {
        color: $white-color;
    }
}
.modal-title {
    font-weight: bold;
    color: $white-color;
}
.navbar {
    .navbar-brand {
        color: $brand-title !important;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;
    }
}
.nav-link {
    p {
        font-size: 13px;
        svg {
            margin-right: 10px;
        }
    }
}
.text-card-title {
    color: $brand-title;
    font-size: 1.3rem;
    font-weight: bold;
}
thead.thead-centered th {
  text-align: center;
}
tfoot.tfoot-centered tr {
  text-align: center;
}
#fault-data-dump, #bearing-life-data {
    td {
        padding: 0.3rem;
    }
}
.fault-codes {
    ul {
        margin: 0;
        padding-inline: 0;
        &.hidden {
            display: none;
        }
        li {
            list-style-type: none;
        }
    }
}
.user-inactive {
  td {
    color: #ccc;
  }
}

;@import "sass-embedded-legacy-load-done:44";