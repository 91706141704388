.btn-custom-primary{
    background-color: #005eb8
}

.btn-custom-primary:hover, .btn-custom-primary:active, .btn-custom-primary:focus {
    background-color: #003172
}

.btn-custom-danger{
    background-color: #003172
}
