.svgLoader {
    animation: spin 0.5s linear infinite;
    margin: auto;
}
.divLoader {
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    background-repeat:no-repeat;
    background-position:center;
    z-index:9999;  
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.tooltip-custom > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 2px solid #ff883e;
    white-space: pre-wrap;
}
  
.tooltip-custom > .tooltip-arrow {
    border-top: 5px solid #ff883e;
}
